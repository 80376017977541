import React from 'react';
import ChatBot, { Button, ChatBotProvider } from "react-chatbotify";

import icon_info from '../pics/mascot/info.png';
import config from '../config.json';

const MyChatBot = () => {

    let res = {
        text: "",
        options: []
    };
    let link;
    let isGreeting;
    const greetings = ['hi', 'hey', 'hello', 'how are you', 'wassup', 'whatsup', 'test'];
    const defaultOptions = ["Telegram", "NFT2.0 News TG-channel"];

	const settings = {
        isOpen: true,
		general: {
            primaryColor: '#4AFEBF',
            secondaryColor: '#51EEDA',
            embedded: false
        },
        notification: {
            disabled: false,
            icon: ''
        },
        tooltip: {
            mode: "START",
            text: "Chat with me!",
        },
        chatButton: {
            icon: icon_info
        },
        header: {
            title: "NFT2 AI Assistant",
            showAvatar: false,
            buttons: [<div><span className="rcb-close-chat-icon" style={{ backgroundImage: "url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgZmlsbD0id2hpdGUiPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe29wYWNpdHk6MC4yO2ZpbGw6bm9uZTtzdHJva2U6IzAwMDAwMDtzdHJva2Utd2lkdGg6NS4wMDAwMDBlLTAyO3N0cm9rZS1taXRlcmxpbWl0OjEwO30KPC9zdHlsZT48ZyBpZD0iZ3JpZF9zeXN0ZW0iLz48ZyBpZD0iX2ljb25zIj48cGF0aCBkPSJNNywyMmgyYzAuNiwwLDEtMC40LDEtMXMtMC40LTEtMS0xSDdjLTEuMSwwLTItMC45LTItMnYtMmMwLTAuNi0wLjQtMS0xLTFzLTEsMC40LTEsMXYyQzMsMjAuMiw0LjgsMjIsNywyMnoiLz48cGF0aCBkPSJNMTUsMjJoMmMyLjIsMCw0LTEuOCw0LTR2LTJjMC0wLjYtMC40LTEtMS0xcy0xLDAuNC0xLDF2MmMwLDEuMS0wLjksMi0yLDJoLTJjLTAuNiwwLTEsMC40LTEsMVMxNC40LDIyLDE1LDIyeiIvPjxwYXRoIGQ9Ik0xNSw1aDJjMS4xLDAsMiwwLjksMiwydjJjMCwwLjYsMC40LDEsMSwxczEtMC40LDEtMVY3YzAtMi4yLTEuOC00LTQtNGgtMmMtMC42LDAtMSwwLjQtMSwxUzE0LjQsNSwxNSw1eiIvPjxwYXRoIGQ9Ik00LDEwYzAuNiwwLDEtMC40LDEtMVY3YzAtMS4xLDAuOS0yLDItMmgyYzAuNiwwLDEtMC40LDEtMVM5LjYsMyw5LDNIN0M0LjgsMywzLDQuOCwzLDd2MkMzLDkuNiwzLjQsMTAsNCwxMHoiLz48L2c+PC9zdmc+')"}} onClick={() => {
                document.querySelector(".rcb-chat-bot").classList.contains('rcb-window-full') ? document.querySelector(".rcb-chat-bot").classList.remove('rcb-window-full') : document.querySelector(".rcb-chat-bot").classList.add('rcb-window-full');
            }}></span></div>, Button.CLOSE_CHAT_BUTTON]
        },
        chatWindow: {
            showScrollbar: true
        },
        chatInput: {
            blockSpam: true
        },
        botBubble: {
            streamSpeed: 15,
            dangerouslySetInnerHtml: true
        },
        footer: {
            text: "",
            buttons: ""
        },
		chatHistory: {
			storageKey: "nft20_chatai"
		}
	};

	const themes = [
		{
            id: "hamilton", 
            version: "0.1.0"
        }
	];

    const styles = {
        chatWindowStyle: {
            backgroundColor: '#22201B'
        },
        bodyStyle: {
            backgroundColor: 'none'
        },
        botBubbleStyle: {
            backgroundColor: '#FFFFFF'
        },
        userBubbleStyle: {
            backgroundColor: '#4AFEBF'
        }
    };

    async function run(prompt) {

        let res;
        let text;
        let options = [];

        try {		
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    prompt: prompt
                })
            };
            const resp = await fetch(config.apiURL, requestOptions);
            res = await resp.json();
        } catch (e) {
            console.log('Cannot connect to AI', e);
        }

        try {
            text = res['text']['result'];
            // shill some projects
            if(text.includes("Envelop")) {
                options = ['Envelop App']
            }
        } catch (e) {
            console.log('Cannot connect to AI');
            text = prompt + " - something is wrong.. Let's try again!";
        }

        return {
            text: text,
            options: options
        };
    }
        
    const flow = {
        start: {
            message: () => {
                const seenBefore = localStorage.getItem("nft20_chatai");
                if (seenBefore) {
                    return `Welcome back!`;
                }
                return "Hi 👋! How can I help you?";
            },
            path: "process_options"
        },
        process_options: {
            message: async (params) => {
                const mes = params.userInput;

                // check if message has a greeting
                // find direct match or first word match
                isGreeting = greetings.some((g) => mes.toLowerCase() === g.toLowerCase() || mes.substring(0, mes.indexOf(" ")).toLowerCase().startsWith(g.toLowerCase()));

                switch (true) {
                    case mes === "Envelop App":
                        link = "https://app.envelop.is";
                        break;
                    case mes === "Telegram":
                        link = "https://t.me/envelop_en";
                        break;
                    case mes === "NFT2.0 News TG-channel":
                        link = "https://t.me/nonfungible_web";
                        break;
                    case isGreeting:
                        link = "";
                        res.text = `${mes} yourself! Let's talk 😊`;
                        break;
                    default:
                        // process with AI
                        link = "";
                        res = await run(params.userInput);
                }

                if(link.length) {
                    // redirect if link is set
                    setTimeout(() => {
                        window.open(link);
                    }, 2000);
                    res.text = `Sit tight! I'll send you to ${mes}!`;
                }
                return res.text;
            },
            component: () => {
                if(res.options.indexOf('Envelop App') !== -1) {
                    link = "https://app.envelop.is";
                    return (
                        <div className="rcb-options-container">
                            <div className="rcb-options" onClick={() => {window.open(link)}}>Envelop App</div>
                        </div>
			        )
                }
                return ""
            },
            path: async () => {
                if(res.options && res.options.length) {
                    res.options = [];
                    return "loop";
                }
                else {
                    return "wait";
                }
            },
            transition: {
                duration: 3000
            },
            // chatDisabled: true
        },
        loop: {
            message: "",
            path: "process_options"
        },
        wait: {
            message: "Need more help? Ask me anything about NFT 2.0 or join us on Telegram",
            options: {
                items: defaultOptions,
                reusable: true
            },
            path: "process_options"
        }
    }
    return (
        <ChatBotProvider>
            <ChatBot settings={settings} themes={themes} styles={styles} flow={flow} />
        </ChatBotProvider>
    );
};

export default MyChatBot;